import React, { useEffect, useState } from "react"
import { useCartContext } from "../../../providers/cart"

export const withPrice = Component => ({ name = "Price", variant, layout, discountAllocationsAmount }) => {
  const { currencyCode } = useCartContext()


  const [price, setPrice] = useState("")
  const [compareAtPrice, setCompareAtPrice] = useState("")
  const [formattedPrice, setFormattedPrice] = useState("")
  const [formattedSalePrice, setFormattedSalePrice] = useState("")


  useEffect(() => {
    if (currencyCode) {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode || "AU",
        currencyDisplay: 'narrowSymbol'
      });
      const presentmentPrice = variant?.presentment_prices?.find(price => price.price.currency_code === currencyCode)
      const basePrice = presentmentPrice?.price?.amount || variant?.priceV2?.amount || variant?.priceV2 || variant?.price || 0
      const compareAtBasePrice =
        presentmentPrice?.compare_at_price?.amount ||
        variant?.compareAtPriceV2?.amount ||
        variant?.compareAtPriceV2 ||
        variant?.compareAtPrice ||
        variant?.compare_at_price ||
        0
      const price = discountAllocationsAmount ? basePrice - discountAllocationsAmount : basePrice
      const compareAtPrice = discountAllocationsAmount || compareAtBasePrice

      setPrice(price)
      setCompareAtPrice(compareAtPrice)
      setFormattedPrice(formatter.format(price))
      setFormattedSalePrice(formatter.format(compareAtPrice))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, variant])

  const isOnSale = price && compareAtPrice && Number(compareAtPrice) > Number(price)

  Component.displayName = name
  return <Component isOnSale={isOnSale} price={formattedPrice} compareAtPrice={formattedSalePrice} layout={layout} />
}
