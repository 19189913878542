import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "../../../providers/cart"
import { useApp } from "../../../hooks/useApp"

export const withCartWidget = Component => ({ name = "CartTotal" }: any) => {
  
  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_CART {
      global: sanityTemplateGlobal {
        additionalCartText
      }
    }
  `)

  const { additionalCartText } = global || {}

  const { globalStateReducer } = useApp()

  const { count } = useCartContext()


  const [{ activeCart }, dispatch] = globalStateReducer

  const handleActiveCart = useCallback(() => {
    dispatch({
      type: "TOGGLE_CART",
      payload: !activeCart,
    })
  }, [dispatch, activeCart])

  const handleMouseMoveIn = useCallback(() => {
    dispatch({
      type: "SHOW_CART",
    })
  }, [dispatch])

  const handleMouseMoveOut = useCallback(() => {
    dispatch({
      type: "HIDE_CART",
    })
  }, [dispatch])

  Component.displayName = name
  return (
    <Component
      count={count}
      activeCart={activeCart}
      handleActiveCart={handleActiveCart}
      handleMouseMoveIn={handleMouseMoveIn}
      handleMouseMoveOut={handleMouseMoveOut}
      additionalCartText={additionalCartText}
    />
  )
}
