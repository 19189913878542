
import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../../../hooks/useFunctions"
import { useCartContext } from "../../../../providers/cart"
import { useCustomerContext } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"
import { useApp } from "../../../../hooks/useApp"
import { useShopify } from "../../../../hooks/useShopify"


export const withCheckout = Component => ({ name = "Checkout" }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { customer } = useCustomerContext()

  const { cart:cartContext, gotoCheckout, giftRegistryCheckoutHandler } = useCartContext()

  const { formatMoney } = useShopify()

  const { loading } = useFunctions()

  const { linkResolver } = useRoutes()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_CHECKOUT {
      cart: sanityPageCart {
        continueShopping: _rawContinueShopping(resolveReferences: { maxDepth: 2 })
        shippingNotes
        additionalCheckoutButton
        additionalCheckoutButtonLoading
        additionalViewBagButton
        freeShippingThreshold
        freeShippingCallToActionMessage
      }
    }
  `)

  const { continueShopping, shippingNotes, additionalCheckoutButton, additionalCheckoutButtonLoading, additionalViewBagButton, freeShippingThreshold, freeShippingCallToActionMessage } = cart || {}

  const handleCheckout = useCallback(
    async event => {
      event.preventDefault()
      const giftRegistryCheckout = await giftRegistryCheckoutHandler()
      gotoCheckout(event, giftRegistryCheckout?.addressPrefill, giftRegistryCheckout?.registryItemsInCart)

    },
    [gotoCheckout, giftRegistryCheckoutHandler, cartContext?.checkoutUrl, customer?.email]
  )

  const freeShippingAmount =
    freeShippingThreshold - Number(cartContext?.cost?.totalAmount?.amount)

  const freeShippingCallToAction =
    freeShippingAmount > 0
      ? freeShippingCallToActionMessage.replace(
        "${amount}",
        formatMoney(freeShippingAmount),
      )
      : ""

  const continueButton = linkResolver(continueShopping)

  Component.displayName = name
  return (
    <Component
      checkoutUrl={cartContext?.checkoutUrl}
      cartUrl={continueButton}
      loading={loading}
      handleCheckout={handleCheckout}
      continueButton={continueButton}
      shippingNotes={shippingNotes}
      additionalCheckoutButton={additionalCheckoutButton}
      additionalCheckoutButtonLoading={additionalCheckoutButtonLoading}
      additionalViewBagButton={additionalViewBagButton}
      freeShippingAmount={freeShippingAmount}
      freeShippingCallToAction={freeShippingCallToAction}
    />
  )
}
