import { useState, useEffect, useCallback, useContext, useMemo } from "react"
import { navigate } from "gatsby"
import { Buffer } from "buffer"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { useFunctions } from "./useFunctions"

import { CustomerContext } from "../providers/customer"
import { useFirebaseContext } from "../providers/firebase"

export const useCustomerContext = () => {
  const { useMutation } = useShopify()

  const {
    helpers: { decodeShopifyId, storage },
    graphql: {
      mutations: {
        CUSTOMER_UPDATE
      }
    }
  } = useCore()

  const customerData: any = useContext(CustomerContext)
  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)


  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await customerUpdate({
        variables: { customer, customerAccessToken },
      })
    },
    [customerUpdate, storage]
  )
  
  return { ...customerData, updateCustomer }
}

export const useCustomerAccessToken = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS },
      queries: { GET_CUSTOMER },
    },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { setCustomer } = useCustomerContext()

  const { useMutation, useQuery } = useShopify()
  // const { updateCustomer } = useCheckout()
  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const [customerAccessTokenCreateWithMultipass] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, { countryCode: storage.get(keys?.country), fetchPolicy: "no-cache", skip: true })

  const getCustomer = useCallback(async () => {
    const customerTokens = storage.get(keys?.customer)

    if (customerTokens?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          countryCode: storage.get(keys?.country),
          customerAccessToken: customerTokens?.accessToken,
        })

        if (!customerUserErrors?.length) {
          setCustomer(customer)
        }
        if (customerUserErrors?.length) storage.remove(keys?.customer)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getCustomerQuery, setCustomer, keys, storage])

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreate({
          variables: { countryCode: storage.get(keys?.country), input: { email, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          // updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreate, getCustomer, keys, storage]
  )

  const createAccessTokenWithMultipass = useCallback(
    async multipassToken => {
      try {
        const {
          data: {
            customerAccessTokenCreateWithMultipass: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreateWithMultipass({
          variables: { multipassToken, countryCode: storage.get(keys?.country) },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          // updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreateWithMultipass, getCustomer, keys, storage]
  )

  return { createAccessToken, createAccessTokenWithMultipass, getCustomer }
}

export const useCustomerRegister = () => {
  const {
    helpers: { isBrowser, storage },
    graphql: {
      mutations: { CUSTOMER_CREATE },
    },
  } = useCore()
  const { useMutation } = useShopify()
  const {
    config: {
      settings: { routes, keys },
    },
  } = useApp()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "", firstName: "", lastName: "", acceptsMarketing: true })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)

  const createCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { countryCode: storage.get(keys?.country), input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

          if (!customerUserErrors?.length) {
            if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerCreate, createAccessToken, routes, isBrowser, storage, keys?.country]
  )

  return { createCustomer, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { createAccessToken } = useCustomerAccessToken()
  const { getUrlParameter } = useRoutes()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "" })
  const isCheckout = getUrlParameter(params.checkoutRef) === "checkout"

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

        if (!customerUserErrors?.length) {
          if (isCheckout) {
            navigate(routes?.DASHBOARD, { state: { checkout: true } })
          } else if (isBrowser) {
            navigate(routes?.DASHBOARD)
          }
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, routes, isBrowser, isCheckout]
  )

  return { loginCustomer, data, setData, loading, errors }
}

export const useCustomerLogout = () => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const { auth } = useFirebaseContext()

  const { setCustomer } = useCustomerContext()

  const logoutCustomer = useCallback(() => {
    auth?.signOut()
    storage.remove(keys?.customer)
    if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
    setCustomer(null)
  }, [setCustomer, keys, storage, routes, isBrowser, auth])

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "" })
  const [success, setSuccess] = useState(false)

  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email, countryCode: storage.get(keys?.country) },
        })

        if (!customerUserErrors?.length) {
          storage.remove(keys?.customer)
          setLoading(false)
          setSuccess(true)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerRecover, storage, keys]
  )

  return { recoverCustomer, data, setData, success, loading, errors }
}

export const useCustomerAccount = () => {
  const {
    helpers: { isBrowser, storage },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { useMutation } = useShopify()
  // const { updateCustomer } = useCheckout()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ password: "", confirmedPassword: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { countryCode: storage.get(keys?.country), id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          // updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerReset, storage, keys, routes, isBrowser]
  )

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { countryCode: storage.get(keys?.country), id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          // updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerActivate, storage, keys, routes, isBrowser]
  )

  return { resetCustomer, activateCustomer, data, setData, loading, errors }
}

export const useCustomerOrders = (first: number, options: { reverse?: boolean } = {}) => {
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useQuery } = useShopify()
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      countryCode: storage.get(keys?.country),
      customerAccessToken,
      first,
      ...options,
    },
  })

  const orders = data?.customer?.orders?.edges

  return { orders, loading, error }
}

export const useCustomerOrder = (orderId, key) => {
  const {
    helpers: { encodeBase64, storage },
    graphql: {
      queries: { GET_ORDER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useQuery } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      countryCode: storage.get(keys?.country),
      id,
    },
  })

  const order = data?.node || {}

  return { order, loading, error }
}

export const useCustomerAddress = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { useMutation, useLazyQuery } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = useMemo(
    () => ({
      address1: "",
      address2: "",
      city: "",
      company: "",
      country: "",
      firstName: "",
      lastName: "",
      phone: "",
      province: "",
      zip: "",
    }),
    []
  )
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState([])
  const [, dispatch] = globalStateReducer
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = useCallback(
    address =>
      Object.keys(address)
        .filter(key => Object.keys(initialData).includes(key))
        .reduce((obj, key) => {
          obj[key] = address[key]
          return obj
        }, {}),
    [initialData]
  )

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      countryCode: storage.get(keys?.country),
      customerAccessToken,
    },
  })

  useEffect(() => {
    getAll()
  }, [saving, getAll])

  useEffect(() => {
    if (data?.customer)
      setAddresses(
        data?.customer?.addresses?.edges?.map(({ node }) => ({
          ...node,
          default: node?.id === data?.customer?.defaultAddress?.id,
        }))
      )
  }, [data])

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerUserErrors, customerAddress },
          },
        } = await customerAddressCreate({
          variables: { countryCode: storage.get(keys?.country), customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          dispatch({
            type: "SET_NEW_ADDRESS_ID",
            payload: customerAddress.id,
          })
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, customerAddressCreate, filterData, initialData, customerAccessToken, dispatch, storage, keys?.country]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { countryCode: storage.get(keys?.country), customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, customerAddressUpdate, filterData, initialData, customerAccessToken, storage, keys?.country]
  )

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { countryCode: storage.get(keys?.country), addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate, customerAccessToken, storage, keys?.country]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { countryCode: storage.get(keys?.country), id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerAddressDelete, customerAccessToken, storage, keys?.country]
  )

  return { addresses, setAddress, address, createAddress, updateAddress, defaultAddress, deleteAddress, initialData, loading, saving, errors }
}

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useLazyQuery, useMutation } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
    }),
    []
  )

  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const [customer, setCustomer] = useState(initialData)
  const { setCustomer: saveCustomer } = useCustomerContext()
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      countryCode: storage.get(keys?.country),
      customerAccessToken,
    },
  })

  const filterData = useCallback(
    (data, hidePassword = false) => {
      return hidePassword
        ? Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .filter(key => key !== "password")
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
        : Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
    },
    [initialData]
  )

  // intentionally only run once at first render
  useEffect(() => {
    if (customerAccessToken?.length) getAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.customer) setCustomer(data?.customer)
  }, [data])

  const updateCustomer = useCallback(
    async customer => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { countryCode: storage.get(keys?.country), customerAccessToken, customer: filterData(customer) },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(prevCustomer => ({
            ...prevCustomer,
            ...filterData(customer, true),
          }))
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [saveCustomer, setErrors, customerUpdate, filterData, customerAccessToken, storage, keys?.country]
  )

  return { customer, setCustomer, updateCustomer, loading, saving, errors }
}

export const useCustomerSocialLogin = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const {
    helpers: { isBrowser },
  } = useCore()

  const { auth, user, providers } = useFirebaseContext()

  const { setSocialLoginErrors: setErrors } = useCustomerContext()

  const { customerMultipass } = useFunctions()

  const { getUrlParameter } = useRoutes()

  const { createAccessTokenWithMultipass } = useCustomerAccessToken()

  const isCheckout = getUrlParameter("ref") === "checkout"

  const loginCustomer = useCallback(
    async providerName => {
      setErrors([])
      window.history.replaceState({}, window.document.title, "/account/login/auth")
      auth.signInWithRedirect(providers[providerName])
    },
    [setErrors, auth, providers]
  )

  const socialLogin = useCallback(async () => {
    if (!user) return
    setErrors([])
    let firebaseToken

    try {
      firebaseToken = await user.getIdToken()
    } catch (err) {
      console.error(err)
      auth.signOut()
      navigate("/account/login")
      setErrors([new Error("Unable to find customer, please try again")])
      return
    }

    try {
      const { multipassToken } = await customerMultipass(firebaseToken)
      const { customerUserErrors } = await createAccessTokenWithMultipass(multipassToken)
      if (!customerUserErrors?.length) {
        if (isCheckout) {
          navigate(routes?.DASHBOARD, { state: { checkout: true } })
        } else if (isBrowser) {
          navigate(routes?.DASHBOARD)
        }
      } else {
        setErrors(customerUserErrors)
      }
    } catch (err) {
      console.error(err)
      auth.signOut()
      navigate("/account/login")
      setErrors([new Error("Unable to find customer, please try again")])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return { loginCustomer, socialLogin }
}
