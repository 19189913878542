import React from "react"

import { withCartDrawer } from "./withCartDrawer"
import {
  Wrapper,
  Header,
  Title,
  Count,
  Divider,
  LineItems,
  Close,
} from "./CartDrawerStyles"
import { Drawer } from "../../Drawer/Drawer"
import { CartItem } from "../Item/CartItem"
import { DiscountCode } from "../Payment/DiscountCode/DiscountCode"
import { Summary } from "../Payment/Summary/Summary"
import { CheckoutDrawer } from "../Payment/Checkout/CheckoutDrawer"
import { CartDrawerEmpty } from "../Empty/CartDrawerEmpty"
import { Icon } from "../../Icon/Icon"

export const CartDrawer = withCartDrawer(
  ({
     active,
     handleClose,
     lineItems,
     count,
     drawerTitle,
     additionalCartDrawerClose,
   }) => (
    <Drawer active={active} handleClose={handleClose}>
      <Wrapper>
        <Header>
          <Title>{drawerTitle}</Title>
          <Close onClick={handleClose}>
            <span>{additionalCartDrawerClose}</span>
            <Icon name={"cross"} width={16} height={16} />
          </Close>
        </Header>
        <Count>
          {count}&nbsp;{count === 1 ? "item" : "items"}
        </Count>
        <Divider />

        {count > 0 ? (
          <>
            <LineItems>
              {lineItems?.map(item => (
                <CartItem
                  key={item?.id}
                  item={item}
                  layout={"drawer"}
                  lineItems={lineItems}
                />
              ))}
            </LineItems>
            <DiscountCode />
            <Summary />
            <CheckoutDrawer />
          </>
        ) : (
          <CartDrawerEmpty />
        )}
      </Wrapper>
    </Drawer>
  ),
)
