import { useCallback, useContext } from "react"

import { TrackingContext } from "../providers/tracking"
import { useCartContext } from "../providers/cart"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

export const useAnalytics = () => {
  const { setTracked, gtm, VisibilitySensor } = useContext(TrackingContext)
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { activeVariant } = useApp()
  const { currencyCode } = useCartContext()

  const { productNormaliser } = useShopify()

  const formatPrice = price => parseFloat(price?.replace(/,/g, "."))?.toFixed(2) || 0

  const getVariantOptionValue = (options, selectedName) => options?.find(({ name }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const trackPageView = useCallback(() => {
    setTimeout(() => {
      // console.log(`[TRACKING] pageView (${document.title}) fired!`)
      gtm.dataLayer({
        dataLayer: {
          event: "Pageview",
          pagePath: document.location.pathname,
          pageTitle: document.title,
        },
        // auth: "Hge-o4A5REHtobb-sbKdsQ", // for environments
        // preview: "env-3", // for environments
      })
    }, 200)
  }, [gtm])

  const decorateUrl = (url: string): string => {
    if (typeof window !== "undefined") {
      const ga = window[window["GoogleAnalyticsObject"]]
      if (ga && typeof ga.getAll === "function") {
        const tracker = ga.getAll()[0]
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const trackProductImpression = useCallback(
    async (product, position, list = null) => {
      const { collections, id, title, vendor, priceRange, price_min, product_type } = productNormaliser(product)
      if (title) {
        // console.log(`[TRACKING] productImpression (${title} ${list}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productImpression",
            ecommerce: {
              currencyCode: currencyCode,
              impressions: [
                {
                  id: decodeShopifyId(id, "Product"),
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || product_type,
                  price: formatPrice(priceRange?.minVariantPrice?.amount || price_min),
                  list: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                  position,
                },
              ],
            },
          },
        })
      }
    },
    [gtm, currencyCode, productNormaliser, decodeShopifyId]
  )

  const trackProductClick = useCallback(
    async (product, variant, position, list = null) => {
      const { collections, id, priceRange, price_min, title, vendor, product_type } = productNormaliser(product)
      if (title) {
        // console.log(`[TRACKING] productClick (${title} – ${variant?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productClick",
            ecommerce: {
              currencyCode: currencyCode,
              click: {
                actionField: { list: list || "Collection Results" }, // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                products: [
                  {
                    id: decodeShopifyId(id, "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || product_type,
                    price: formatPrice(priceRange?.minVariantPrice?.amount || price_min),
                    position,
                    variant: getVariantOptionValue(variant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                    // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, currencyCode, productNormaliser, decodeShopifyId]
  )

  const trackProductView = useCallback(
    async (product, list = null) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        // console.log(`[TRACKING] productView (${title} – ${activeVariant?.title}) fired!`)
        const shopifyProduct = JSON.parse(product.shopify.raw)
        gtm.dataLayer({
          dataLayer: {
            event: "productDetail",
            ecommerce: {
              currencyCode: currencyCode,
              detail: {
                actionField: { list: list || "Product Page" }, // Product Page, Quick View
                products: [
                  {
                    id: product.shopify.id,
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: formatPrice(activeVariant?.priceV2?.amount || (shopifyProduct?.variants && shopifyProduct?.variants[0]?.priceV2)),
                    priceExTax: formatPrice(activeVariant?.priceV2?.amount || (shopifyProduct?.variants && shopifyProduct?.variants[0]?.priceV2)),
                    variant: getVariantOptionValue(activeVariant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(activeVariant?.selectedOptions, `Size`),
                    // dimension3: activeVariant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, activeVariant, currencyCode, productNormaliser, decodeShopifyId]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(({ variant }) => variant?.id === variantId)[0]
      if (selectedLineItem?.title) {
        // console.log(`[TRACKING] cartUpdate (${type} – ${selectedLineItem?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: type === "add" ? "addToCart" : "removeFromCart",
            ecommerce: {
              currencyCode: currencyCode,
              [type]: {
                products: [
                  {
                    id: decodeShopifyId(variantId, "ProductVariant"),
                    name: selectedLineItem?.title,
                    brand: selectedLineItem?.variant?.product?.vendor,
                    category: selectedLineItem?.variant?.product?.productType,
                    price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
                    quantity,
                    variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Size`),
                    // dimension3: selectedLineItem?.variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, currencyCode, decodeShopifyId]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        // console.log(`[TRACKING] promoImpression (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionView",
            ecommerce: {
              promoView: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        // console.log(`[TRACKING] promoClick (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionClick",
            ecommerce: {
              promoClick: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm]
  )

  const trackEvent = useCallback(async () => {
    if (currencyCode) {
      await setTracked(true)
      await trackPageView()
    }
  }, [currencyCode, setTracked, trackPageView])

  return {
    trackEvent,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartUpdate,
    trackPromoImpression,
    trackPromoClick,
    decorateUrl,
    VisibilitySensor,
  }
}
