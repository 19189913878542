import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"

export const withCartItem = Component => ({ name = "CartItem", item, layout = "", lineItems }: any) => {

  const {
    loading: cartLoading,
    loadingRemove,
    removeFromCart,
    updateQuantity,
  } = useCart() 


  const { cart, productTemplateData } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_ITEM {
      cart: sanityPageCart {
        additionalPreOrderText
        additionalGiftCardSenderLabel
        additionalGiftCardReceiverLabel
        additionalGiftCardReceiverEmailLabel
      }
      productTemplateData: sanityTemplateProduct {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
    }
  `)

  const { additionalPreOrderText, additionalGiftCardSenderLabel, additionalGiftCardReceiverLabel, additionalGiftCardReceiverEmailLabel } = cart || {}
  const { settingGiftCardProduct } = productTemplateData || {}

  const {
    config: {
      settings: {
        giftCardConstraints,
        routes: { PRODUCT },
        keys: { preOrderKey, preOrderValue },
      },
    },
  } = useApp()

  const [inputQuantity, setInputQuantity] = useState(item?.quantity)

  const [warning, setWarning] = useState("")

  const isPreOrder =
    item?.attributes?.find(attribute => attribute.key === preOrderKey)?.value === preOrderValue

  const getQuantityAvailable = quantity => {
    if(item?.merchandise?.requiresShipping === false){
      return -1
    }
    return (
      quantity > 0 &&
      (quantity <= item?.merchandise?.quantityAvailable || isPreOrder)
    )
  }

  const currentVariantInOtherLineItemsTotalQuantity = lineItems
    .filter(currentItem => currentItem.id !== item?.id)
    .reduce(
      (acc, cur) =>
        cur.merchandise.id === item?.merchandise?.id ? acc + cur.quantity : acc,
      0,
    )

  const isAddQuantityDisabled =
    item?.merchandise?.requiresShipping === false ? false : cartLoading ||
      (item?.merchandise?.quantityAvailable -
        currentVariantInOtherLineItemsTotalQuantity <=
        item?.quantity &&
        !isPreOrder)


  const handleQuantity = quantity => {
    setInputQuantity(quantity)
    if (getQuantityAvailable(quantity)) {
      updateQuantity(item?.id, item?.merchandise?.id, quantity)
      setWarning("")
    } else {
      setWarning(
        item?.merchandise?.quantityAvailable
      )
    }
  }

  const handleRemove = () => removeFromCart(item?.id, item?.merchandise?.id)


  const handleChange = ({ target: { value } }) => {
    const quantity = parseInt(value, 10)
    setInputQuantity(quantity)
    if (getQuantityAvailable(quantity)) {
      updateQuantity(item?.id, item?.merchandise?.id, quantity)
      setWarning("")
    } else {
      setWarning(
          item?.merchandise?.quantityAvailable
      )
    }
  }

  // intentionally ignore inputQuantity in the dependencies, this hook will only run when cart item quantity change.
  useEffect(() => {
    if (item?.quantity !== inputQuantity) {
      if (cartLoading) {
        setInputQuantity(inputQuantity)
      } else {
        setInputQuantity(item?.quantity)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.quantity, cartLoading])

  const url = `${PRODUCT}/${item?.merchandise?.product?.handle}`

  const isGiftCard =
    item?.merchandise?.product?.handle === settingGiftCardProduct?.shopify?.handle

  const discountMessage = item?.attributes
    .find(item => item?.key === "_appliedPromotionRules")
    ?.value?.split(",")

  const discountAllocationsAmount =
    item?.discountAllocations?.reduce(
      (acc, cur) => acc + Number(cur.allocatedAmount?.amount),
      0,
    ) / item.quantity

  const isFreeGift = item?.attributes
    .find(item => item.key === "_promotionalFreeGift")
    ?.value?.split(",")

  const itemMessage = item?.merchandise?.product?.tags
    ?.find(tag => tag.includes("message:"))

  Component.displayName = name
  return (
    <Component
      layout={layout}
      isGiftCard={isGiftCard}
      item={item}
      url={url}
      loading={cartLoading}
      loadingRemove={loadingRemove}
      handleQuantity={handleQuantity}
      handleChange={handleChange}
      inputQuantity={inputQuantity}
      isAddQuantityDisabled={isAddQuantityDisabled}
      warning={warning}
      isPreOrder={isPreOrder}
      handleRemove={handleRemove}
      additionalPreOrderText={additionalPreOrderText}
      additionalGiftCardSenderLabel={additionalGiftCardSenderLabel}
      additionalGiftCardReceiverLabel={additionalGiftCardReceiverLabel}
      additionalGiftCardReceiverEmailLabel={
        additionalGiftCardReceiverEmailLabel
      }
      discountAllocationsAmount={discountAllocationsAmount}
      discountMessage={discountMessage}
      isFreeGift={isFreeGift}
      itemMessage={itemMessage}
      giftCardConstraints={giftCardConstraints}
    />
  )
}
