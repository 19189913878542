import React, { createContext, useState } from "react"
import { useShopContext } from "../hooks/useShop"
import { useSettings } from "../hooks/useSettings"
import { useCartContext } from "./cart"

import config from "../../config.default"

export const CurrencyContext: any = createContext({})

export const CurrencyProvider = ({ children }): JSX.Element => {
  const { currencyCode } = useCartContext()
  const { shop } = useShopContext()
  const { currencies } = useSettings()

  const sanityCurrencies = currencies?.edges?.[0]?.node?.languages || config.app.currencies

  const findActiveCurrency = sanityCurrencies.find(ele => (currencyCode || 'AUD') == ele.currencyCode )

  const [currentCurrency, updateCurrentCurrency] = useState(findActiveCurrency)
  const [newCurrency, updateNewCurrency] = useState('')
  const [showRedirectPopup, toggleRedirectPopup] = useState(false)

  const values = {
    currentCurrency,
    newCurrency,
    showRedirectPopup,
    updateNewCurrency,
    updateCurrentCurrency,
    toggleRedirectPopup
  }

  return <CurrencyContext.Provider value={{ ...values }}>{children}</CurrencyContext.Provider>
}
