import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCart } from "../../../../hooks/useCart"

export const withDiscountCode = Component => ({ name = "DiscountCode", layout = "cart" }) => {
  const { applyDiscountCode, loading, errors } = useCart()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_DISCOUNT {
      cart: sanityPageCart {
        additionalDiscountCodePlaceholder
        additionalDiscountCodeSubmitingButtonText
        additionalDiscountCodeSubmitButtonText
        additionalDiscountCodeErrorMessage
      }
    }
  `)

  const {
    additionalDiscountCodePlaceholder,
    additionalDiscountCodeSubmitingButtonText,
    additionalDiscountCodeSubmitButtonText,
    additionalDiscountCodeErrorMessage,
  } = cart || {}

  const [discountCode, setDiscountCode] = useState("")

  const handleApply = () => {
    applyDiscountCode(discountCode)
  }

  const handleChange = ({ target: { value } }) => {
    setDiscountCode(value)
  }

  const error = errors?.find(error => error.code === "DISCOUNT_NOT_FOUND")
  Component.displayName = name
  return (
    <Component
      discountCode={discountCode}
      handleApply={handleApply}
      handleChange={handleChange}
      loading={loading}
      error={error}
      additionalDiscountCodePlaceholder={additionalDiscountCodePlaceholder}
      additionalDiscountCodeSubmitingButtonText={additionalDiscountCodeSubmitingButtonText}
      additionalDiscountCodeSubmitButtonText={additionalDiscountCodeSubmitButtonText}
      additionalDiscountCodeErrorMessage={additionalDiscountCodeErrorMessage}
      layout={layout}
    />
  )
}
