const configDefault = {
  app: {
    title: "Driza-Bone",
    description:
      "Australia's Original Outerwear Brand - Driza-Bone. For 120 years we've been hard at work developing outerwear and protecting Australians from the wet weather.",
    url: "https://drizabone.com.au",
    logo: "static/images/logo.png",
    themeColor: "#000000",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
    currencies: [
      {
        name: "AUD",
        symbol: "$"
      },
      {
        name: "CAD",
        symbol: "$"
      },
      {
        name: "EUR",
        symbol: "€"
      },
      {
        name: "GBP",
        symbol: "£"
      },
      {
        name: "HKD",
        symbol: "$"
      },
      {
        name: "JPY",
        symbol: "¥"
      },
      {
        name: "NZD",
        symbol: "$"
      },
      {
        name: "SGD",
        symbol: "$"
      },
      {
        name: "USD",
        symbol: "$"
      }
    ]
  },
  theme: {
    logo: "static/images/logo.svg",
    themeColor: "#ffffff",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
    color: "#ffffff",
    background: "#FFFF04",
    display: "minimal-ui",
    icon: "static/images/icon.png",
  },
  stores: {
    ...("production" === process.env.GATSBY_ACTIVE_ENV && {
      "driza-bone": {
        siteLocation: "AU",
        siteCountries: ["AU", "US"],
        shopName: "driza-bone",
        shopDomain: "checkout.drizabone.com.au",
        shopifyShopDomain: "drizabone.myshopify.com",
        accessToken: "751eb2df22b9a73cfb5c1c5c7f1df126",
        sanityProjectId: "c4rhoo6t",
        sanityDataset: "production",
        searchIndex: "driza-bone-search-v2",
      },
    }),
    ...("development" === process.env.GATSBY_ACTIVE_ENV && {
      "driza-bone-stage": {
        siteLocation: "AU",
        siteCountries: ["AU", "US"],
        shopName: "driza-bone-stage",
        shopDomain: "drizabone-stage.myshopify.com",
        shopifyShopDomain: "drizabone-stage.myshopify.com",
        accessToken: "b00aa61fbd06f54d409a47dcbec5ee4c",
        sanityProjectId: "c4rhoo6t",
        sanityDataset: "staging",
        searchIndex: "driza-bone-search-stage-v2",
      },
    }),
  },
  get store() {
    return this.stores[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]
  },
  services: {
    shopify: {
      apiVersion: "2025-01",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    sanity: {
      projectId: "c4rhoo6t",
      token:
        "skt8AQfUPMtMfazhwWySBgitDFLt4vZJ8iUThUltI6DdYDjccGaRKKslI96urZzzMuaitGCGC58S1F0DQvvT4Mk60m3M2YPMB3GLw4xZ4IMiG19H0eWfqvBcc5MoOEhNGnb8t6JiHANMGqSnbawcDlkqeaZjTDofWmTwBBmW3c3TGv6vfFsg",
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
    functions: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint: "https://australia-southeast1-drizabone-website.cloudfunctions.net",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint: "https://australia-southeast1-drizabone-website-stage.cloudfunctions.net",
      }),
    },
    firebase: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        databaseURL: "https://drizabone-website.firebaseio.com",
        apiKey: "AIzaSyBz_hvZVgTnDo7g2MJZEGWkiU-Q0UWYgA8",
        authDomain: "drizabone-website.firebaseapp.com",
        projectId: "drizabone-website",
        storageBucket: "drizabone-website.appspot.com",
        messagingSenderId: "454891336662",
        appId: "1:454891336662:web:4ff8163f30fde8164baa77",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        databaseURL: "https://drizabone-website-stage.firebaseio.com",
        apiKey: "AIzaSyAIRHV8VD8pDx4_dsYG-RZ5IGEY1XkGHU8",
        authDomain: "drizabone-website-stage.firebaseapp.com",
        projectId: "drizabone-website-stage",
        storageBucket: "drizabone-website-stage.appspot.com",
        messagingSenderId: "532399204059",
        appId: "1:532399204059:web:2625406dd2152578414efe",
      }),
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        searchUrl: "https://api.search.reactify.app/",
        searchConfig: "https://config.search.reactify.app?shop=drizabone.myshopify.com",
        searchFieldKey: "q",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        searchUrl: "https://api.search.reactify.app/",
        searchConfig: "https://config.search.reactify.app?shop=drizabone-stage.myshopify.com",
        searchFieldKey: "q",
      }),
    },
    googleMaps: {
      apiKey: "AIzaSyBF5oKPzs_l6exVYI15WgjlXiNCDLFNBXs",
    },
    instagram: {
      url: "https://www.instagram.com/p",
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: {shopify: {shopifyPublished: {eq: true}, shopifyDeleted: {ne: true}}}) { edges { node { tags, shopify { handle: shopifyHandle, shopifyRaw, deleted: shopifyDeleted }   } } }`,
      template: "templates/product.tsx",
      hidden: "hidden",
      siblingTagPrefix: "colours",
      devPageLimit: 100,
    },
    collections: {
      query: `allSanityCollection { edges { node { shopify {  handle: shopifyHandle } } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 100,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id slug { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 100,
    },
    flexible: {
      query: `allSanityPageFlexible(filter: { _id: { regex: "/^((?!drafts).)*$/" } }) { edges { node { _id handle: slug { current } } } },`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id slug { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    keys: {
      shopify: "drizabone:shopify",
      country: "drizabone:country",
      location: "drizabone:location",
      checkout: "drizabone:checkout",
      discounts: "drizabone:discounts",
      customer: "drizabone:customer",
      wishlist: "drizabone:wishlist",
      announcement: "drizabone:announcement",
      cookies: "drizabone:cookies",
      maintenance: "drizabone:maintenance",
      newsletter: "drizabone:newsletter",
      password: "drizabone:password",
      preOrderKey: "Pre-order",
      preOrderValue: "Yes",
      collectionCardView: "collectionCardView",
      cart: "cart",
      market: "market"
    },
    params: {
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      wishlist: "id",
      articlesFilter: "filter",
      articlesSortBy: "sortby",
      articlesPage: "page",
      checkoutRef: "ref",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/welcome",
      SAVED: "/wishlist",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      RETURNS: "/account/returns",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/articles",
      STORE: "/stores",
    },
    tags: {
      badge: "badge",
      message: "message",
      siblings: "product_line",
      swatchColor: "swatch_colour",
      feature: "feature",
      productCode: "mapping-code",
      sizeGuide: "size_template",
    },
    constraints: {
      SIZE: "Size",
      COLOUR: "Colour",
      SPEC: "fullProductDescription",
      CARE: "careInstructions",
      FIT: "fit",
      MODEL_WEARING: "modelWearing",
      FEMALE_VIEW: "W",
      UNISEX_VIEW: "U",
      MALE_VIEW: "M",
      productMetafields: [
        { key: "modelWearing", namespace: "marketing" },
        { key: "fullProductDescription", namespace: "marketing" },
        { key: "fit", namespace: "marketing" },
        { key: "careInstructions", namespace: "marketing" },
        { key: "hide", namespace: "da-restock" },
      ],
    },
    giftCardConstraints: {
      DIGITAL: "digital",
      PHYSICAL: "physical",
      CUSTOMAMOUNT: "customAmount",
      SENDER: "From",
      RECEIVER: "To",
      RECEIVER_EMAIL: "Email",
      MESSAGE: "Message",
    },
    defaultRedirects: [
      { source: "/collection", destination: "/collections", type: 301 },
      { source: "/checkout", destination: "/cart", type: 301 },
      { source: "/home", destination: "/", type: 301 },
    ],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/search",
      "/cart",
      "/cart/login",
    ],
    socialMedia: {
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
      },
    },
  },
}

module.exports = configDefault