import React from "react"

import { withCartItem } from "./withCartItem"
import {
  Wrapper,
  Thumbnail,
  Details,
  InnerTitleWrapper,
  InnerTitle,
  OuterTitle,
  QuantityWrapper,
  QuantityButton,
  QuantityNumber,
  Remove,
  SelectedOptions,
  StyledRow,
  Warning,
  DiscountMessage,
  FreeGiftMessage,
} from "./CartItemStyles"
import { Image } from "../../Image/Image"
import { Icon } from "../../Icon/Icon"
import { Link } from "gatsby"
import { PriceTag } from "../../Product/Price/PriceStyles"
import { CartItemPrice } from "./Price/CartItemPrice"
import { Message } from "../../Product/Message/Message"

export const CartItem = withCartItem(
  ({
    layout,
    isGiftCard,
    item,
    url,
    loading,
    loadingRemove,
    handleQuantity,
    handleChange,
    handleRemove,
    inputQuantity,
    warning,
    isAddQuantityDisabled,
    isPreOrder,
    additionalRemoveLineItemButtonText,
    additionalRemovingLineItemButtonText,
    additionalPreOrderText,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardReceiverEmailLabel,
    giftCardConstraints,
    discountMessage,
    discountAllocationsAmount,
    isFreeGift,
    itemMessage,
  }): JSX.Element => (
    <Wrapper layout={layout} loading={loading || loadingRemove ? "true" : "false"}>
      <Thumbnail as={Link} to={url} title={item?.merchandise?.product?.title} image={item?.merchandise?.image?.originalSrc ? "true" : "false"} layout={layout}>
        <Image image={{...item?.merchandise?.image, src: item?.merchandise?.image?.originalSrc }} ratio={"15/19"} />
      </Thumbnail>
      <Details>
        {isFreeGift && <FreeGiftMessage>Free Gift</FreeGiftMessage>}
        <OuterTitle as={Link} to={url} title={item?.merchandise?.product?.title} layout={layout}>
          {item?.merchandise?.product?.title}
        </OuterTitle>
        <StyledRow>
          <InnerTitleWrapper>
            <InnerTitle as={Link} to={url} title={item?.merchandise?.product?.title} layout={layout}>
              {item?.merchandise?.product?.title}
            </InnerTitle>
            {!isGiftCard ? (
              <SelectedOptions>
                {item?.merchandise?.selectedOptions.map(option => (
                  <span key={option.name}>{`${option.name}: ${option.value}`}</span>
                ))}
                {isPreOrder ? <b>{additionalPreOrderText}</b> : null}
              </SelectedOptions>
            ) : (
              <SelectedOptions>
                {item?.attributes?.map(attribute => {
                  if (attribute.value !== "") {
                    return (
                      <span key={attribute?.key}>
                        {attribute?.key}: {attribute.value}
                      </span>
                    )
                  }
                })}
              </SelectedOptions>
            )}
            <Message tags={item?.merchandise?.product?.tags} layout={"cart"} />
          </InnerTitleWrapper>
          {!isGiftCard && !isFreeGift ? (
            <div>
              <QuantityWrapper>
                <QuantityButton title={"minus"} disabled={loading || item?.quantity === 1} onClick={() => handleQuantity(item?.quantity - 1)}>
                  <Icon name={"minus"} width={10} height={10} />
                </QuantityButton>
                <QuantityNumber disabled={loading} type={"number"} value={inputQuantity} onChange={handleChange} />
                <QuantityButton title={"add"} disabled={isAddQuantityDisabled} onClick={() => handleQuantity(item?.quantity + 1)}>
                  <Icon name={"plus"} width={10} height={10} />
                </QuantityButton>
              </QuantityWrapper>
            </div>
          ) : null}
        </StyledRow>
        {warning ? (
          <StyledRow>
            <Warning>{warning}</Warning>
          </StyledRow>
        ) : null}
        <StyledRow align="center">
          <Remove disabled={loadingRemove || loading} onClick={handleRemove}>
            <Icon name={"remove"} />
            <span className={"label"}>{loadingRemove ? "Removing" : "Remove"}</span>
          </Remove>
          {isGiftCard ? (
            <PriceTag layout={layout}>${(item.variant?.priceV2?.amount * item.quantity).toFixed(2)}</PriceTag>
          ) : (
            <CartItemPrice layout={layout} variant={item?.merchandise} discountAllocationsAmount={discountAllocationsAmount} />
          )}
        </StyledRow>

        {discountMessage?.length > 0 && discountMessage.map(message => <DiscountMessage key={message}>{message}</DiscountMessage>)}
      </Details>
    </Wrapper>
  )
)
