import React, { useCallback, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useShopifyProduct } from "../../hooks/useShopify"
import { useAnalytics } from "../../hooks/useAnalytics"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useCartContext } from "../../providers/cart"
import { useApp } from "../../hooks/useApp"
import { useDotdigital } from "../../hooks/useDotdigital"
import { useEmarsys } from "../../hooks/useEmarsys"
import { useCustomerContext } from "../../hooks/useCustomer"
import { usePrevious } from "../../hooks/usePrevious"

export const withLayout = Component => ({ name = "Layout", location, path, children, data, settings: { settings } }: any) => {
  const { globalStateReducer } = useApp()
  const { currencyCode, cart } = useCartContext();


  const { active, authorised } = useMaintenance(location)
  const { selectProduct } = useShopifyProduct()
  const { trackEvent } = useAnalytics()
  const {
    emarsysLoaded,
    trackPage
  } = useEmarsys()
  const { customer } = useCustomerContext()
  const prevCustomer = usePrevious(customer)
  const prevCheckout = usePrevious(cart)
  const { trackWebBehavior } = useDotdigital()

  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_MENU_LAYOUT {
      global: sanityTemplateGlobal {
        headerDesktopNavigationFormat
        headerMobileNavigationFormat
      }
    }
  `)

  const { headerDesktopNavigationFormat, headerMobileNavigationFormat } = global || {}

  useEffect(() => {
    selectProduct(data?.product, location?.pathname)
  }, [location?.pathname, currencyCode, data?.product, selectProduct])

  useEffect(() => {
    trackEvent()
    trackWebBehavior()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname])

  // Emarsys Web tracking, the customer identify, cart and go are required to be fired on every page.
  useEffect(() => {
    if (emarsysLoaded && cart) {
      trackEmarsys()
    }
  }, [emarsysLoaded])

  useEffect(() => {
    const logout = !customer && prevCustomer

    // Don't track if logging out, it will be tracked in the path change useEffect
    if (emarsysLoaded && !logout) {
      trackEmarsys()
    }
  }, [customer])

  useEffect(() => {
    if (emarsysLoaded && !prevCheckout && cart) {
      trackEmarsys()
    }
  }, [cart])

  useEffect(() => {
    const login = !customer && path === "/account/"

    // Don't track logging in, it will be tracked in the customer change useEffect
    if (emarsysLoaded && cart && !login) {
      trackEmarsys()
    }
  }, [path])

  const [{ activeMenu }, dispatch] = globalStateReducer

  useEffect(() => {
    dispatch({
      type: "ROUTE_CHANGE",
    })
  }, [path, dispatch])

  const trackEmarsys = () => {
    trackPage(customer, cart, data?.collection, data?.product?.shopify?.id)
  }

  const homepage = location?.pathname === "/"
  const routes = settings?.routes

  const handleCloseMenu = () => {
    dispatch({
      type: "CLOSE_MENU",
    })
  }

  const setHeight = useCallback(() => {
    document.documentElement.style.setProperty(`--inner-height`, `${window.innerHeight}px`)
  }, [])

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setHeight()
      window.addEventListener("resize", () => {
        setHeight()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Component.displayName = name
  return active ? (
    <>{children}</>
  ) : (
    authorised && (
      <Component
        data={data}
        location={location}
        routes={routes}
        homepage={homepage}
        activeMenu={activeMenu}
        mobileNavigationLayout={headerMobileNavigationFormat || "slide"}
        desktopNavigationFormat={headerDesktopNavigationFormat || "mega"}
        handleCloseMenu={handleCloseMenu}
        transparentHeader={data?.page?.transparentHeader}
      >
        {children}
      </Component>
    )
  )
}
