import gql from "graphql-tag"

export const METAFIELD_FRAGMENT = gql`
  fragment MetafieldFragment on Metafield {
    id
    key
    type
    value
    namespace
  }
`
