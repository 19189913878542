import React, { PropsWithChildren } from "react"
import config from "./../../config.default"

type ContextProps = Config

export const ConfigContext = React.createContext<ContextProps | undefined>(undefined)

export const ConfigProvider = ({ children }: PropsWithChildren) => {


  const activeConfig = React.useMemo<ContextProps>(
    () =>
    {
        return  ({
            ...config
          }) as Config
    }
    ,
    [config]
  )

  const contextValue = React.useMemo<ContextProps>(() => activeConfig, [activeConfig])

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}

export const useConfigContext = (): ContextProps => ({ ...React.useContext(ConfigContext) }) as ContextProps
