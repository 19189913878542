import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "../../../providers/cart"
import { useApp } from "../../../hooks/useApp"

export const withCartDrawer = Component => ({ name = "CartDrawer" }) => {
  const { globalStateReducer } = useApp()
  const { cart:cartContext, count } = useCartContext()
  const { cart } = useStaticQuery(graphql`
      query SANITY_PAGE_CART_DRAWER {
          cart: sanityPageCart {
              drawerTitle
          }
      }
  `)

  const { drawerTitle, additionalCartDrawerClose } = cart || {}

  const [{ activeCart }, dispatch] = globalStateReducer

  const handleClose = () => dispatch({ type: "HIDE_CART" })

  Component.displayName = name
  return (
    <Component
      active={activeCart}
      lineItems={cartContext?.lines}
      count={count}
      handleClose={handleClose}
      drawerTitle={drawerTitle}
    />
  )
}
