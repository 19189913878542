import { useState, useCallback, useMemo } from "react"
import { useCore, useStorage } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useAnalytics } from "./useAnalytics"
import { useEmarsys } from "./useEmarsys"
import { useKlaviyo } from "./useKlaviyo"
import { useCustomerContext } from "./useCustomer"
import { useCartContext } from "../providers/cart"

export const useCart = () => {

  const {
    helpers: { decodeShopifyId, storage },
    graphql: {
      mutations: {
        CART_LINE_ADD, 
        CART_LINE_UPDATE, 
        CART_LINE_REMOVE, 
        CART_DISCOUNT_CODES_UPDATE
      }
    }
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { customer } = useCustomerContext()
  const { id:cartId, cart, saveCart } = useCartContext()

  const { useMutation } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsys()
  const { trackKlaviyoAddToCart } = useKlaviyo();
  const { cartNormaliser } = useShopify()


  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [errors, setErrors] = useState([])
  const [, dispatch] = globalStateReducer
  const [cartLinesRemove] = useMutation(CART_LINE_REMOVE)
  const [cartLinesUpdate] = useMutation(CART_LINE_UPDATE)
  const [discountCodeUpdate] = useMutation(CART_DISCOUNT_CODES_UPDATE)
  const [cartLinesAdd] = useMutation(CART_LINE_ADD)

  const addToCart = useCallback(
    async (product: any, variant: NormalisedVariant, quantity = 1, attributes: Attribute[] = []) => {
      setLoading(true)
      // Remove empty attributes if any, this causes an error in the cart
      attributes = attributes?.filter(({ value }) => !!value)

      const lines = {
        attributes:
          attributes?.map(({ key, value }) => ({
            key,
            value,
          })) || [],
        quantity: quantity,
        merchandiseId: variant.id,
      }

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          cartId,
          lines,
        },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        saveCart(data.cart)
        dispatch({
          type: "SHOW_CART"
        })

        setLoading(false)
        const normalisedCart = cartNormaliser(data.cart)
        trackCartUpdate("add", variant.id, quantity, normalisedCart?.lines)
        trackPage(customer, normalisedCart)
        trackKlaviyoAddToCart(variant.id, quantity, normalisedCart)
      }
    },
    [cartLinesAdd, cartId, cartNormaliser, decodeShopifyId, saveCart, dispatch, setLoading]
  )

  const removeFromCart = useCallback(
    async (id: string, variantId: string) => {
      setLoadingRemove(true)

      const quantity = cart?.lines.filter(line => line.id === id).map(({ quantity }) => quantity)[0] || 1
      const lineIds = cart?.lines.filter(line => line.id === id).map(line => line.id)
  
      // Tracking Event
      const normalisedCart = cartNormaliser(cart)
      trackCartUpdate("remove", variantId, quantity, normalisedCart?.lines || [])


      const {
        data: { cartLinesRemove: data },
      } = await cartLinesRemove({
        variables: { cartId, lineIds },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoadingRemove(false)
      }

      // Tracking Event
      if (data.cart) {
        saveCart(data?.cart)
        trackPage(customer, normalisedCart)
      }
      setLoadingRemove(false)
    },
    [cart?.lines, cartId, cartNormaliser, saveCart, setLoading]
  )

  const updateQuantity = useCallback(
    async (id: string, variantId: string, quantity: number, action = "change") => {
      setLoading(true)

      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity: quantity,
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }


      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)

        trackPage(customer, normalisedCart)
        trackCartUpdate(action, variantId, quantity, normalisedCart?.lines)
      }
      setLoading(false)
    },
    [saveCart, cart, cartLinesUpdate, cartId, cartNormaliser, setLoading]
  )

  const applyDiscountCode = useCallback(
    async (discountCode: string) => {
      setLoading(true)

      // convert to array
      const discountCodes = [discountCode];
      // if adding a code, append all existing codes to array too
      // if removing code and passing empty string, don't ned to do this.
      if (discountCode) {
        cart?.discountCodes?.forEach((code) => {
          discountCodes.push(code?.code)
        })
      }

      const {
        data: { cartDiscountCodesUpdate: data },
      } = await discountCodeUpdate({
        variables: { cartId, discountCodes },
      })

      if (data?.cart?.discountCodes.length && data?.cart?.discountCodes[0]?.applicable != true ) {
        setErrors([{code: "DISCOUNT_NOT_FOUND"}])
        setLoading(false)
      }
      else {
        setErrors([])
        setLoading(false)
      }

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        saveCart(data.cart)
      }
      setLoading(false)
    },
    [saveCart, cart, cartLinesUpdate, cartId, cartNormaliser, setLoading]
  )

  return {
    errors,
    loading,
    loadingRemove,
    addToCart,
    updateQuantity,
    removeFromCart,
    applyDiscountCode,   
  }
}
