import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "./imageFragment"
import { MEDIA_IMAGE_FRAGMENT, MEDIA_VIDEO_FRAGMENT, MEDIA_EXTERNAL_VIDEO_FRAGMENT, MEDIA_MODEL_FRAGMENT } from "./mediaFragment"
import { PRICE_RANGE_FRAGMENT } from "./priceRangeFragment"
import { VARIANT_FRAGMENT } from "./variantFragment"

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    handle
    title
    description
    descriptionHtml
    productType
    vendor
    tags
    availableForSale
    publishedAt
    updatedAt
    createdAt
    options {
      id
      name
      values
    }
    priceRange {
      ...PriceRangeFragment
    }
    images(first: $firstImages) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
    media(first: $firstMedia) {
      edges {
        node {
          mediaContentType
          ...MediaImageFragment
          ...MediaVideoFragment
          ...MediaExternalVideoFragment
          ...MediaModel3dFragment
        }
      }
    }
    variants(first: $firstVariants, after: $afterVariants) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    collections(first: $firstCollections) {
      edges {
        node {
          id
          title
          handle
          description
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${MEDIA_IMAGE_FRAGMENT}
  ${MEDIA_VIDEO_FRAGMENT}
  ${MEDIA_EXTERNAL_VIDEO_FRAGMENT}
  ${MEDIA_MODEL_FRAGMENT}
  ${PRICE_RANGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`
