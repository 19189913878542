import React, { memo, useMemo } from "react"
import { useCurrency } from "../../hooks/useCurrency"
import { useSettings } from "../../hooks/useSettings"
import { useCartContext } from "../../providers/cart"

import config from "../../../config.default"

export const withLocationPopup = Component => memo(({ name = "LocationPopup" }: any) => {
  const { newCurrency, showRedirectPopup, toggleRedirectPopup } = useCurrency()
  const { currencyCode } = useCartContext()
  const { currencies } = useSettings()

  const sanityCurrencies = currencies?.edges?.[0]?.node?.languages || config.app.currencies

  const activeCurrency = sanityCurrencies.find(ele => currencyCode == ele.currencyCode)

  Component.displayName = name

  return useMemo(
    () => sanityCurrencies.length && (
      <Component
      current={activeCurrency}
      currency={newCurrency}
      show={showRedirectPopup}
      toggleLocationPopup={() => toggleRedirectPopup(false)} />
    ), [showRedirectPopup]
  )  
})