import { useContext } from "react"
import { CurrencyContext } from "../providers/currency"

export const useCurrency = () => {
  const { currentCurrency, newCurrency, showRedirectPopup, toggleRedirectPopup, updateNewCurrency, updateCurrentCurrency } = useContext(CurrencyContext)

  return {
    currentCurrency,
    newCurrency,
    showRedirectPopup,
    toggleRedirectPopup,
    updateNewCurrency,
    updateCurrentCurrency
  }
}
